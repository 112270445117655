export default Object.freeze({
  unset: 'unset',
  document: 'document',
  agreement: 'agreement',
  form: 'form',
  /**
   * If this type is present, it will display the "Your Experience" tab in the
   * registration details view.
   */
  workExperienceAndQualifications: 'workExperienceAndQualifications',
})
